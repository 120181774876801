<template lang="pug">
  footer#Footer
    .wrapper
      .container
        .row
          .footer__links.col-xs-12.col-sm-6.col-md-6.col-lg-6
            //-ul
              li 
                router-link(:to="{name: 'home'}" exact) {{ $t("footer.links.home") }}
              li 
                router-link(:to="{name: 'about'}" exact) {{ $t("footer.links.about") }}
              li 
                //- router-link(:to="{name: 'cases'}" exact) {{ $t("footer.links.cases") }}
              li 
                router-link(:to="{name: 'jobs'}" exact) {{ $t("footer.links.jobs") }}
          .footer__logo.col-xs-12.col-sm-6.col-md-6.col-lg-6
            svg(width="64" height="64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250")
              path(fill="#FFF" d="M160.6,243.9H7.6v-49.7l71.8-51.5c12-8.5,12-13.7,12-15.4c0-5.8-6.3-6.4-9-6.4c-7.4,0-15,5.1-27.8,18.9 l-3.9,4.2L5,105.1l3.7-4.6c19-23.4,39.6-39,78.5-39c41.5,0,71.7,25.5,71.7,60.7v0.5c0,29.8-17.5,45.2-36.4,57.4l-12.5,8h50.6 V243.9z")
              circle(fill="#51AAD2" cx="208.2" cy="43" r="36.8")
          .footer__copyrigth.col-xs-12.col-sm-6.col-md-6.col-lg-6
            p © {{ currentYear }} - 2mundos. {{ $t("footer.all_rights_reserved") }}
          .footer__languages.col-xs-12.col-sm-6.col-md-6.col-lg-6
            ul
              li 
                span(@click="chooseLanguage('us')") English
              //- li 
              //-   span(@click="chooseLanguage('br')") Português
              li 
                span(@click="chooseLanguage('es')") Español
</template>

<script>
import selectLanguage from "@/mixins/selectLanguage";
export default {
  name: "Footer",
  components: {},
  mixins: [selectLanguage],
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  data() {
    return {};
  },
  methods: {},
  validations: {}
};
</script>

<style lang="scss" scoped>
footer {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  z-index: 10;
  background-color: #000;
  font-size: 0.8rem;
  color: #fff;
  padding: 50px 0;
}
.footer__links {
  margin-bottom: 26px;
  a {
    text-decoration: none;
    line-height: 28px;
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
}
.footer__logo {
  text-align: right;
}
.footer__copyrigth {
  p {
    font-size: 0.7rem;
  }
}
.footer__languages {
  ul {
    float: right;
    li {
      display: inline-block;
      span {
        padding-left: 5px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      &:not(:last-child) {
        span {
          padding-right: 5px;
          border-right: 1px solid #fff;
        }
      }
    }
  }
}
@media screen and (max-width: 769px) {
  footer {
    text-align: center;
  }
  .footer__links {
    margin-bottom: 55px;
  }
  .footer__copyrigth {
    order: 1;
  }
  .footer__logo {
    text-align: center;
  }
  .footer__languages {
    ul {
      float: none;
    }
  }
}
</style>
