<template lang="pug">
    #ContactForm
        form(@submit="submitContactForm" :class="{ 'hasError': $v.$error }")
            .field(:class="{'error': $v.form.name.$error, 'valid': !$v.form.name.$invalid}")
                input(type="text", name="Name", :placeholder="$t('contact.form.placeholder.name')", tabindex="1", v-model.trim="$v.form.name.$model", required, autocomplete="off")
            .field(:class="{'error': $v.form.email.$error, 'valid': !$v.form.email.$invalid}")
                input(type="email", name="Email", :placeholder="$t('contact.form.placeholder.email')", tabindex="2", v-model.trim="$v.form.email.$model", required, autocomplete="off")
            .field(:class="{'error': $v.form.phone.$error, 'valid': !$v.form.phone.$invalid}")
                the-mask(type="text", name="Phone", :placeholder="$t('contact.form.placeholder.phone')", tabindex="3", v-model.trim="$v.form.phone.$model", :mask="['(##) ####-####', '(##) #####-####']" required autocomplete="off")
            .field(:class="{'error': $v.form.message.$error, 'valid': !$v.form.message.$invalid}")
                input(type="text", name="Message", :placeholder="$t('contact.form.placeholder.message')", tabindex="4", v-model.trim="$v.form.message.$model", required, autocomplete="off")
            .field.input__check
                input(type="text", name="check", :placeholder="$t('contact.form.placeholder.dont_fill')", tabindex="5" v-model.trim="form.verification")
            .messages(v-if="form.error")
              .error.message {{ $t('contact.form.placeholder.dont_fill') }}
            button.button(action="submit" tabindex="5" :class="{'loading': form.loading, 'sended': form.sended}")
              img.loader(src="../../assets/images/loader.svg" v-if="form.loading")
              <svg v-if="form.sended" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
              span(v-if="!form.loading && !form.sended") {{ $t('buttons.send_now') }}
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import axios from "axios";
import { setTimeout } from "timers";

export default {
  name: "ContactForm",
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
        origin: "br",
        verification: "",
        error: false,
        loading: false,
        sended: false
      }
    };
  },
  methods: {
    submitContactForm(e) {
      e.preventDefault();
      let vm = this;

      vm.form.loading = true;

      // if its still pending or an error is returned do not submit
      if (vm.form.verification || this.$v.$pending || this.$v.$error) {
        vm.form.error = true;
        return;
      }

      let _payload = {
        to_email: 'info@2mundos.net',
        name: vm.form.name,
        email: vm.form.email,
        phone: vm.form.phone,
        message: vm.form.message,
        origin: vm.form.origin
      };
      _payload = JSON.stringify(_payload);

      axios
        .post(
          DM_ENV.API_URL + '/contact',
          _payload,
          {
            headers: {
              "Content-type": "application/json"
            }
          }
        )
        .then(_response => {
          vm.form.error = false;
          vm.form.sended = true;

          vm.form.name = "";
          vm.form.email = "";
          vm.form.phone = "";
          vm.form.message = "";
          vm.$v.$reset();

          setTimeout(() => {
            vm.form.sended = false;
          }, 3000);
        })
        .catch(_error => {
          vm.form.error = true;
          console.log("Contact form error: ", _error);
        })
        .finally(() => {
          vm.form.loading = false;
        });
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      },
      phone: {
        required
      },
      message: {
        required,
        minLength: minLength(3)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#ContactForm {
  form {
    margin-bottom: 4rem;
    .field {
      display: block;
      margin: 1rem 0 3rem;
      width: 100%;
      border-bottom: 1px solid #fff;
      position: relative;
      transition: 0.3s ease;
      &.input__check {
        position: absolute;
        left: -9999rem;
      }
      &:after {
        position: absolute;
        right: 10px;
        bottom: 20px;
        content: "";
        display: inline-block;
        background-repeat: no-repeat;
        transition: 0.3s ease;
      }
      &.error {
        border-bottom: 1px solid #d0021b;
        &:after {
          width: 14px;
          height: 14px;
          background-image: url("../../assets/images/error.svg");
        }
      }
      &.valid {
        &:after {
          width: 18px;
          height: 15px;
          background-image: url("../../assets/images/check.svg");
        }
      }
      input {
        width: 100%;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 300;
        padding: 1rem 0.5rem;
        border: none;
        outline: transparent;
        background-color: transparent;
        &::placeholder {
          color: #fff;
          opacity: 0.6;
        }
      }
    }
    .button {
      position: relative;
      width: 160px;
      height: 55px;
      margin: 0;
      overflow: hidden;
      transition: 0.3s ease;
      border: none;
      outline: none;
      box-shadow: none;
      &.loading {
        width: 90px;
      }
      &.sended {
        width: 53px;
        padding-right: 0;
        pointer-events: none;
        cursor: default;
      }
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .loader {
      width: 16px;
      height: 16px;
    }
  }
}
.messages {
  .message {
    box-sizing: border-box;
    border-radius: 50px;
    padding: 1rem 2rem;
    line-height: 1;
    margin-bottom: 20px;
    text-align: center;
  }
  .error {
    background-color: #d0021b;
    color: #fff;
  }
}

// Colors
$green: #7ac142;
$white: #fff;

// Misc
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.checkmark {
  position: absolute;
  left: 0;
  top: -1px;
  width: 53px;
  height: 53px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $green;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $green;
  fill: none;
  animation: stroke 0.6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s $curve 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $green;
  }
}
</style>
