export default {
  created() {
    if (!localStorage.currentLanguage) {
      localStorage.currentLanguage = this.$store.state.currentLanguage;
    }
  },
  methods: {
    chooseLanguage(lang) {
      let redirect_url;
      if (lang == "us") {
        redirect_url = window.DM_ENV.US_WEBSITE_DOMAIN;
      } else if (lang == "es") {
        redirect_url = window.DM_ENV.ES_WEBSITE_DOMAIN;
      }
      if (redirect_url) {        
        redirect_url = window.location.protocol + '//' + redirect_url;
        window.location.assign(redirect_url);
      }
    }
  }
};
