<template lang="pug">
  div#ContactContainer
    .world-map(v-if="!isMobile")
      img(src="../../assets/images/Map_footer.png")
      .dot.san-diego
        span San Diego
      .dot.sao-paulo
        span São Paulo
      .dot.madri
        span Madrid
    .container
      .row.start-lg.center-xs
        .col-lg-4.col-sm-8.col-xs-10
          .box
            h2 {{ $t("contact.title") }}
            p {{ $t("contact.text") }}
    .world-map-mobile( v-if="isMobile")
      .map
        img(src="../../assets/images/Map_footer_mobile.png")
        .dot.san-diego
          span San Diego
        .dot.sao-paulo
          span São Paulo
        .dot.madri
          span Madrid
    .container
      .row.end-lg.center-xs
        .col-lg-4.col-sm-8.col-xs-10
          .box
            ContactForm
      .row.between-lg.center-xs(v-if="!isMobile")
        .start-col-xs
          .box
            p San Diego, {{ $t("places.california") }}
        .center-col-xs
          .box
            p São Paulo, {{ $t("places.brazil") }}  
        .end-col-xs
          .box
            p Madri, {{ $t("places.spain") }}  
</template>

<script>
import ContactForm from "../../components/ContactForm/ContactForm";

export default {
  name: "ContactSection",
  components: {
    ContactForm
  },
  data() {
    return {
      isMobile: false
    };
  },
  methods: {
    mobileBreakpointCheck() {
      let vm = this;
      let breakPoint = 1200;
      if (window.innerWidth > breakPoint) {
        vm.isMobile = false;
      } else {
        vm.isMobile = true;
      }
    }
  },
  created() {
    let vm = this;
    $(window).resize(function() {
      vm.mobileBreakpointCheck();
    });
  },
  mounted() {
    let vm = this;
    vm.mobileBreakpointCheck();
  }
};
</script>

<style lang="scss">
/* Section Contact */
#ContactContainer {
  position: relative;
  .world-map {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    object-fit: contain;
    transform: translate(-50%, -50%);
    z-index: -1;
    img {
      width: 100%;
      opacity: 0.8;
      object-fit: contain;
    }
    .dot {
      width: 20px;
      height: 20px;
      position: absolute;
      span {
        position: absolute;
        top: 0;
        left: 30px;
        width: 100px;
        display: inline-block;
        user-select: none;
      }
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #f1a939;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        background-color: #fff;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        opacity: 0.2;
        animation: pulse 2s infinite;
        transform: translate(-50%, -50%) scale(1);
      }
      &.san-diego {
        top: 35.5%;
        left: 10.8%;
        &:before {
          animation-delay: 0.4s;
        }
      }
      &.sao-paulo {
        top: 68.3%;
        left: 29.3%;
        &:before {
          animation-delay: 0.8s;
        }
      }
      &.madri {
        top: 31.3%;
        left: 41.8%;
      }
    }
  }
  .world-map-mobile {
    position: relative;
    margin: -20vh 0;
    opacity: 0.8;
    .map {
      position: relative;
      width: 100vw;
      height: 100vw;
      img {
        width: 100vw;
        height: 100vw;
        object-fit: contain;
      }
      .dot {
        width: 20px;
        height: 20px;
        position: absolute;
        transform: translate(-50%, -50%);
        span {
          position: absolute;
          top: 25px;
          left: 100%;
          width: 100px;
          display: inline-block;
          user-select: none;
          transform: translate(-50%, 0);
        }
        &:after {
          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
          top: 50%;
          left: 50%;
          background-color: #f1a939;
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }
        &:before {
          content: "";
          width: 20px;
          height: 20px;
          position: absolute;
          background-color: #fff;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          opacity: 0.2;
          animation: pulse 2s infinite;
          transform: translate(-50%, -50%) scale(1);
        }
        &.san-diego {
          top: 37%;
          left: 8.7%;
          &:before {
            animation-delay: 0.4s;
          }
        }
        &.sao-paulo {
          top: 69.4%;
          left: 52.2%;
          &:before {
            animation-delay: 0.8s;
          }
        }
        &.madri {
          top: 33.2%;
          left: 81.2%;
        }
      }
    }
  }
}
</style>
