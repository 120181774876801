<template lang="pug">
  main(:class="{'fixedPosition':menuOpen }")
    Header
    slot
    Footer
</template>

<script>
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

export default {
  name: "Main",
  components: {
    Footer,
    Header
  },
  data() {
    return {
      menuOpen: false
    };
  },
  created() {
    let vm = this;
    vm.$bus.$on("menuToggle", function(data) {
      vm.menuOpen = data;
    });
  }
};
</script>

<style lang="scss" scoped>
</style>